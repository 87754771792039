<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 用户管理 -->
  <section class="page-child-contianer">
    <div class="table-box">
      <!-- 搜索表单 -->
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="标题">
          <el-input v-model="searchForm.name" placeholder="标题"></el-input>
        </el-form-item>

        <el-form-item label="发布项目">
          <el-select v-model="searchForm.project_id" placeholder="发布项目">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="searchForm.status" placeholder="状态">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in articleStatus"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否为幻灯片">
          <el-select
            v-model="searchForm.is_recommend"
            placeholder="是否为幻灯片"
          >
            <el-option label="全部" value="" />
            <el-option
              v-for="item in recommendList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 数据内容 -->
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              type="primary"
              v-if="smallRoleObj.add"
              icon="el-icon-plus"
              @click="$router.push({ name: 'ARTICLE_ADD' })"
            >
              添加
            </el-button>
            <el-button
              size="small"
              type="danger"
              v-if="smallRoleObj.delete"
              icon="el-icon-delete"
              @click="handleDelete()"
            >
              删除
            </el-button>
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            v-loading="tableLoading"
            ref="tableBox"
            border
            style="width: 100%"
            :max-height="tableHeight"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              fixed="left"
            />
            <el-table-column prop="id" label="ID" width="80" align="center" />
            <el-table-column
              prop="name"
              label="资讯标题"
              width="180"
              align="center"
            />
            <el-table-column
              prop="status_name"
              label="显示状态"
              width="180"
              align="center"
            />
            <el-table-column
              prop="project_names"
              label="发布范围"
              width="180"
              align="center"
            >
              <template slot-scope="scope" v-if="scope.row.project_names">
                <span
                  v-if="scope.row.project_names.length > 20"
                  :title="scope.row.project_names"
                  >{{ scope.row.project_names.substring(0, 20) }}...</span
                >
                <span v-else>{{ scope.row.project_names }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="is_recommend_name"
              label="是否设置为幻灯片推荐"
              align="center"
            />
            <el-table-column
              prop="thumb_path_source"
              label="封面图"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <el-avatar
                  shape="square"
                  :size="80"
                  fit="cover"
                  :src="scope.row.thumb_path_source"
                >
                  <img
                    src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                  />
                </el-avatar>
              </template>
            </el-table-column>

            <el-table-column
              prop="do_member_name"
              label="操作人员"
              align="center"
            />

            <el-table-column label="更新时间" width="210" align="center">
              <template slot-scope="scope">
                <p>
                  {{
                    (scope.row.update_time * 1000)
                      | formatTime("YYYY-MM-DD HH:mm:ss")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="240"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="color-primary"
                  type="text"
                  v-if="smallRoleObj.update"
                  icon="el-icon-edit"
                  @click="
                    $router.push({
                      name: 'ARTICLE_EDIT',
                      params: { id: scope.row.id },
                    })
                  "
                >
                  编辑
                </el-button>
                <el-button
                  v-if="smallRoleObj.top"
                  :class="{
                    'color-danger': scope.row.is_recommend == 1,
                    'color-success': scope.row.is_recommend == 2,
                  }"
                  :icon="
                    (scope.row.is_recommend == 1 && 'el-icon-download') ||
                    'el-icon-upload2'
                  "
                  type="text"
                  @click="
                    handleRecommendUpdate(scope.row.id, scope.row.is_recommend)
                  "
                >
                  {{
                    (scope.row.is_recommend == 2 && "置为幻灯") || "取消幻灯"
                  }}
                </el-button>

                <el-button
                  class="color-danger"
                  type="text"
                  v-if="smallRoleObj.delete"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.id)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页控件 -->
        <el-pagination
          background
          layout="total,sizes,prev, pager, next,jumper"
          :current-page="pagination.current"
          :page-size="pagination.size"
          :page-sizes="pagination.sizes"
          :total="pagination.total"
          @size-change="handleSize"
          @current-change="handleCurrent"
        >
        </el-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import {
  getArticleList,
  articleDelete,
  articleUpdate,
  articleFieldUpdate,
} from "@api/article";
import { getProjectList } from "@api/project";
import { PAGE_SIZE, PAGE_SIZES } from "@config";
export default {
  name: "articleList",
  inject: ["reload"],
  data() {
    return {
      tableLoading: false,
      tableHeight: 0, //表格最大高度
      searchForm: {
        branch_id: "",
      }, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      multipleSelection: [], //表格多选储存数据

      articleStatus: [
        {
          value: "1",
          name: "发布",
        },
        {
          value: "2",
          name: "禁用",
        },
      ],

      recommendList: [
        {
          value: "1",
          name: "是",
        },
        {
          value: "2",
          name: "否",
        },
      ],
      projectList: [],
      smallRoleObj: {},
      pagination: {
        page: 1,
        total: 0,
        current: 1,
        size: PAGE_SIZE,
        sizes: PAGE_SIZES,
      }, //分页数据
    };
  },
  computed: {
    ...mapState("system", ["branchListSearch"]),
  },
  async created() {
    let res = await getProjectList();
    this.projectList = res.data;
    let tempParams = {
      page: this.pagination.page,
      rows: this.pagination.size,
    };
    this.getData(tempParams);
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  async mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
    this.smallRoleObj = await this.validateSmallRole();
    this.$forceUpdate();
  },
  methods: {
    /** 获取表格数据 */
    async getData(tempParams) {
      this.tableLoading = true;
      /**
       * 请求接口数据
       */
      let res = await getArticleList(tempParams);
      this.tableData = res.data.list;

      this.pagination.total = res.data.count;
      this.tableLoading = false;
      this.$forceUpdate();
    },
    /** 分页参数-每页显示条数改变 */
    handleSize(val) {
      this.pagination = {
        ...this.pagination,
        size: val,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 分页参数-当前页改变 */
    handleCurrent(val) {
      this.pagination = {
        ...this.pagination,
        page: val,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 查询 */
    handleSearch() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 表格多选触发 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCancle() {
      this.dialogFormVisible = false;
      this.mobileUpdateId = 0;
      this.mobileUpdateForm = {};
    },
    /** 删除用户 */
    handleDelete(id) {
      /** 判断id是否存在，存在即单个删除。反之触发批量删除 */
      if (!id && id != 0) {
        if (this.multipleSelection.length > 0) {
          id = this.multipleSelection.map((item) => item.id);
        }
      }

      if (id || id == 0) {
        id = (id instanceof Array && id.join(",")) || id;

        /** 弹窗再次确认操作 */
        this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          /** 确认触发 */
          .then(async () => {
            /**
             * 请求接口删除数据
             */
            let res = await articleDelete(id);
            // this.$refs['tableBox'].clearSelection(); //删除操作请求失败，清空表格多选

            /** 判断当前页数据是否仅剩1条 */
            if (this.tableData.length == 1) {
              this.pagination = {
                ...this.pagination,
                page:
                  (this.pagination.page > 1 && this.pagination.page - 1) || 1, // 判断当前页是否为第一页，不为第一页则减少一页
              };
            }
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
            }
            let tempParams = {
              ...this.searchForm,
              page: this.pagination.page,
              rows: this.pagination.size,
            };
            this.getData(tempParams);
          })
          /** 取消触发 */
          .catch(() => {
            this.$refs["tableBox"].clearSelection(); //删除操作取消，清空表格多选
            this.$message({
              type: "info",
              message: "已取消删除操作",
            });
          });
      }
    },
    /**
     * 推荐幻灯变更
     */
    async handleRecommendUpdate(id, is_recommend) {
      let params = {
        id: id,
        field_name: "is_recommend",
      };
      if (is_recommend) {
        params.field_value = (is_recommend == 1 && 2) || 1;
      }
      let res = await articleFieldUpdate(params);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }

      this.getData();
    },
  },
};
</script>
